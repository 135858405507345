import { createRouter, createWebHistory } from 'vue-router'

import HomeView from './pages/home'
import LoginView from './pages/login'
import UserView from './pages/account/user'
import EnterpriseView from './pages/account/enterprise'
import TagsView from './pages/tags'
import PortsView from './pages/ports'
import HsCodeView from './pages/hscode'
import ContainerSizeView from './pages/containerSize'
import Stat from './pages/stat'

const routes = [
  { path: '/', redirect: 'home' },
  { path: '/home', name: 'home', component: HomeView },
  { path: '/login', name: 'login', component: LoginView },
  { path: '/account/enterprise', name: 'enterprise', component: EnterpriseView },
  { path: '/account/user', name: 'user', component: UserView },
  { path: '/tags', name: 'tags', component: TagsView },
  { path: '/ports', name: 'ports', component: PortsView },
  { path: '/hscode', name: 'hscode', component: HsCodeView },
  { path: '/container/size', name: 'containerSize', component: ContainerSizeView },
  { path: '/stat', name: 'stat', component: Stat },
]

export default createRouter({
  history: createWebHistory(),
  routes,
})
