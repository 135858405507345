<template>
  <el-breadcrumb :separator-icon="ArrowRight" style="margin-bottom: 24px;">
    <template v-for="(item, index) in breadcrumbList" :key="index">
      <el-breadcrumb-item :to="{ path: item.path }" v-if="item.path">{{item.label}}</el-breadcrumb-item>
      <el-breadcrumb-item v-else>{{item.label}}</el-breadcrumb-item>
    </template>
  </el-breadcrumb>
</template>

<script setup>
import {ref, watch} from 'vue'
import { ArrowRight } from '@element-plus/icons-vue'
import { useRoute } from 'vue-router'
import {onMounted} from "vue";
const route = useRoute()

const breadcrumbList = ref([])
const breadcrumbMap = {
  home: [
    { label: 'Sigma', path: '/' },
    { label: '首页管理' },
  ],
  enterprise: [
    { label: 'Sigma', path: '/' },
    { label: '账号管理' },
    { label: '企业账号' },
  ],
  user: [
    { label: 'Sigma', path: '/' },
    { label: '账号管理' },
    { label: '用户账号' },
  ],
  tags: [
    { label: 'Sigma', path: '/' },
    { label: '标签管理' }
  ],
  ports: [
    { label: 'Sigma', path: '/' },
    { label: '物流字典' },
    { label: '全球港口' },
  ],
  hscode: [
    { label: 'Sigma', path: '/' },
    { label: '物流字典' },
    { label: 'HS CODE' },
  ],
  containerSize: [
    { label: 'Sigma', path: '/' },
    { label: '物流字典' },
    { label: '集装箱尺寸' },
  ],
  stat: [
    { label: 'Sigma', path: '/' },
    { label: '数据统计' },
  ],
}
const init = () => {
  breadcrumbList.value = breadcrumbMap[route.name || 'home']
}
onMounted(() => {
  init()
})
watch(() => route.name, () => {
  init()
})

</script>

<style scoped lang="less">
  @import "index.less";
</style>
