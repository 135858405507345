<template>
  <section class="page-header">
    <div class="logo"><img src="@/assets/logo.png" /></div>
    <div class="header-main">
      <div />
      <el-dropdown>
        <el-link style="font-size: 16px;">
          <el-icon><User /></el-icon>
          <span style="margin-left: 4px;margin-right: 8px;">{{ userInfo?.username }}</span>
          <el-icon>
            <ArrowDown />
          </el-icon>
        </el-link>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item><el-link type="default" @click="logoutHandle">退出登录</el-link></el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </section>
</template>

<script setup>
  import {onMounted, ref} from "vue";
  import * as Utils from '@/assets/utils'
  import { ArrowDown, User } from '@element-plus/icons-vue'

  const userInfo = ref(null)
  const getUserInfo = () => {
    userInfo.value = Utils.user.getInfo()
  }
  onMounted(() => {
    getUserInfo()
  })

  const logoutHandle = () => {
    Utils.user.logout()
    window.location.href = '/login';
  }
</script>

<style scoped lang="less">
  @import "index.less";
</style>
