<template>
  <ListFilter :formList="filterFormList" @search="filterSearchHandle" />

  <el-button type="primary" style="margin-bottom: 16px;" @click="toggleDrawer(true)" :icon="Plus">新增</el-button>
  <el-button type="danger" style="margin-bottom: 16px;" @click="forbidToggleHandle(multipleSelection, 2)" :disabled="multipleSelection.length === 0">批量禁用</el-button>
  <el-button type="default" style="margin-bottom: 16px;" @click="forbidToggleHandle(multipleSelection, 1)" :disabled="multipleSelection.length === 0">批量启用</el-button>
  <el-button type="default" style="margin-bottom: 16px;" @click="multiSettingToggleHandle(true)" :disabled="multipleSelection.length === 0">批量设置标签</el-button>
  <el-table border :data="list" style="width: 100%" @selection-change="handleSelectionChange">
    <el-table-column type="selection" width="55" />
    <el-table-column prop="name" label="公司名称" />
    <el-table-column prop="tagsDesc" label="标签">
      <template #default="scope">
        <div style="display: flex; gap: 4px">
          <el-tag type="default" v-for="(item, index) in scope.row.tagsDesc" :key="index">{{item}}</el-tag>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="titleNames" label="关联下单抬头">
      <template #default="scope">
        <div style="display: flex; gap: 4px; flex-wrap: wrap;">
          <el-tag type="default" v-for="(item, index) in scope.row.titleNames" :key="index">{{item}}</el-tag>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="status" label="状态">
      <template #default="scope">
        <el-tag :type="({ 1: 'success', 2: 'danger' })[scope.row.status]">{{({ 0: '全部', 1: '启用', 2: '禁用' })[scope.row.status]}}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="edit" label="操作" fixed="right">
      <template #default="scope">
        <el-button link type="primary" size="small" @click="editHandle({...scope.row})">编辑</el-button>

        <el-popconfirm :title="`确定${({ 1: '禁用', 2: '启用' })[scope.row.status]}?`">
          <template #reference>
            <el-button link :type="({ 1: 'danger', 2: 'default' })[scope.row.status]" size="small">{{ ({ 1: '禁用', 2: '启用' })[scope.row.status] }}</el-button>
          </template>
          <template #actions="{ confirm, cancel }">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button type="danger" size="small" @click="forbidToggleHandle([scope.row.id], ({ 1: 2, 2: 1 })[scope.row.status], confirm)">确定</el-button>
          </template>
        </el-popconfirm>

        <!-- <el-popconfirm title="确定删除?">
          <template #reference>
            <el-button link type="danger" size="small">删除</el-button>
          </template>
          <template #actions="{ confirm, cancel }">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button type="danger" size="small" @click="delHandle(scope.row, confirm)">确定</el-button>
          </template>
        </el-popconfirm> -->
      </template>
    </el-table-column>
  </el-table>
  <Pagination
    :page="page"
    :size="size"
    :total="total"
    @current-change="val => page = val"
  />

  <el-drawer v-model="drawer" direction="rtl" size="555px" :close-on-click-modal="false" :destroy-on-close="true" @close="handleDrawerClose">
    <template #header>
      <h4>编辑</h4>
    </template>
    <template #default>
      <div>
        <el-form :model="form" label-width="auto" style="max-width: 600px">
          <el-form-item label="公司名称" required>
            <el-input v-model="form.name" size="large" placeholder="请输入公司名称" />
          </el-form-item>
          <el-form-item label="标签">
            <el-select v-model="form.tagIds" multiple size="large" placeholder="请选择标签类型">
              <el-option
                v-for="item in tagsList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <div slot="extra">无合适标签？<el-link type="primary" href="/tags">新增标签</el-link></div>
          </el-form-item>
          <el-form-item label="下单抬头">
            <el-select v-model="form.titleIds" :multiple="true" size="large" placeholder="请选择标签类型">
              <el-option
                v-for="item in titleList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="toggleDrawer(false)">取消</el-button>
        <el-button type="primary" @click="submitHandle" :disabled="!form.name" :loading="loading">保存</el-button>
      </div>
    </template>
  </el-drawer>

  <el-dialog
    v-model="dialogVisible"
    title="批量设置企业标签信息"
    width="568"
  >
    <el-form :model="multiForm" label-width="auto" style="max-width: 600px">
      <el-form-item label="标签">
        <el-select v-model="multiForm.tagIds" multiple size="large" placeholder="请选择标签类型">
          <el-option
            v-for="item in tagsList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <div slot="extra">无合适标签？<el-link type="primary" href="/tags">新增标签</el-link></div>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="multiSettingTagHandle">保存</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
  import {onMounted, ref, watch} from 'vue'
  import * as Request from "@/assets/request";
  import {ElMessage} from "element-plus";
  import ListFilter from '@/components/listFliter'
  import Pagination from '@/components/spagination'
  import { Plus } from '@element-plus/icons-vue'

  const list = ref([])
  const listQuery = ref({})
  const total = ref(0)
  const size = ref(50)
  const page = ref(1)
  const getList = async () => {
    const result = await Request.post({
      url: '/company/query',
      data: {
        ...listQuery.value,
        page: page.value,
        size: size.value
      }
    })
    if(result?.status === 200) {
      list.value = result.data.list;
      total.value = result.data.total;
    }
  }
  onMounted(() => {
    getList()
  })
  watch(() => [ listQuery.value, page.value ], () => {
    getList();
  }, { deep: true })

  const tagsList = ref([])
  const getTagsList = async () => {
    const result = await Request.get({
      url: '/tag/getTag/1/1',
      data: {}
    })
    if(result?.status === 200) {
      tagsList.value = result.data;
    }
  }
  onMounted(() => {
    getTagsList()
  })

  const titleList = ref([])
  const getTitleList = async () => {
    const result = await Request.post({
      url: '/company/queryTitle',
      data: {}
    })
    if(result?.status === 200) {
      titleList.value = result.data.list;
    }
  }
  onMounted(() => {
    getTitleList()
  })

  const drawer = ref(false)
  const handleDrawerClose = () => {
    form.value = {}
  }
  const form = ref({
    // id: null,
    name: '',
    titleIds: [],
    tagIds: [],
  })
  const toggleDrawer = (flag) => {
    drawer.value = flag
  }
  const editHandle = (item) => {
    toggleDrawer(true)
    form.value = item
  }
  const loading = ref(false)
  const submitHandle = async () => {
    let url = '/company/add';
    if(form.value?.id){
      url = '/company/update';
    }
    loading.value = true
    const result = await Request.post({
      url,
      data: form.value
    })
    loading.value = false
    if(result?.status === 200) {
      ElMessage.success('保存成功');
      toggleDrawer(false)
      getList()
      form.value = {
        // id: null,
        name: '',
        titleIds: [],
        tagIds: [],
      }
    }
  }
  // const delHandle = async (data, callback) => {
  //   const result = await Request.del({
  //     url: `/company/delete/${data.id}`
  //   })
  //   if(result?.status === 200) {
  //     ElMessage.success('操作成功')

  //     callback()
  //     getList()
  //   }
  // }

  const forbidToggleHandle = async (ids, target, callback) => {
    const url = ({ 2: '/company/disable', 1: '/company/enable' })[target]
    const result = await Request.post({
      url,
      data: ids
    })
    if(result?.status === 200) {
      ElMessage.success('保存成功');

      if(typeof callback === 'function'){
        callback()
      }
      getList()
      form.value = {
        // id: null,
        type: 1,
        status: 1,
        name: '',
      }
      multipleSelection.value = []
    }
  }

  const multipleSelection = ref([])
  const handleSelectionChange = (val) => {
    multipleSelection.value = val.map(v => v.id)
  }

  const multiForm = ref({
    tagIds: []
  })
  const dialogVisible = ref(false)
  const multiSettingToggleHandle = (flag) => {
    dialogVisible.value = flag
  }
  const multiSettingTagHandle = async () => {
    const ids = multipleSelection.value
    const tagIds = multiForm.value.tagIds

    const result = await Request.post({
      url: '/company/assignTag',
      data: { ids, tagIds }
    })
    if(result?.status === 200) {
      ElMessage.success('保存成功');
      multiSettingToggleHandle(false)
      getList()

      multiForm.value = {
        tagIds: []
      }
      multipleSelection.value = []
    }
  }

  /**
   * filter
   * @type {Ref<UnwrapRef<*[]>, UnwrapRef<*[]> | *[]>}
   */
  const filterFormList = ref([])
  const filterFormListInit = () => {
    filterFormList.value = [
      {
        type: 'input', field: 'fuzzyName',
        label: '公司名称', placeholder: '请输入',
      },
      {
        type: 'select', field: 'tagId',
        label: '标签', placeholder: '请选择',
        options: tagsList.value?.map(item => ({ value: item.id, label: item.name })),
        style: "width: 156px;"
      }
    ]
  }
  onMounted(() => {
    filterFormListInit()
  })
  watch(() => tagsList.value, (tagsList) => {
    filterFormListInit(tagsList)
  })
  const filterSearchHandle = (formValue) => {
    listQuery.value = {...formValue}
    page.value = 1
  }
</script>

<style scoped lang="less">
@import "index.less";
</style>
