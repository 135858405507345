import axios from "axios";
import { ElMessage } from 'element-plus'
import * as Utils from './utils'

export const baseURL = process.env.NODE_ENV === 'production' ? '/api' : '/'

export const request = (options = {}) => {
  const { url, data, method } = options;

  const instance = axios.create({
    baseURL,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    timeout: 8000
  });
  instance.interceptors.request.use(config => {
    config.headers['Access-Control-Allow-Origin'] = '*';

    const token = Utils.user.getToken();
    if (token) {
      config.headers['Authorization'] = token;
    }

    return config
  });
  instance.interceptors.response.use(
    (res) => {
      if (res?.data?.status === 200) {
        return Promise.resolve(res.data)
      } else {
        if(res?.data?.msg) {
          ElMessage.error(res?.data?.msg)
        }
        return Promise.resolve(res.data)
      }
    },
    (err) => {
      if (err?.response?.status === 401) {
        console.error('401')
        //未登录或登录过期
        Utils.user.logout();
        window.location.href = '/login';
        return;
      }

      console.error('err', err.message);
      return Promise.reject(err);
    }
  );

  return instance({ url, data, method });
};

export const post = (options = {}, hideErr = false) => {
  return request({
    ...options,
    method: 'post'
  }).catch(err => {
    console.log('err', err)
    if(!hideErr) {
      Utils.toast(err);
      return false;
    }
    return { error: err }
  });
};

export const del = (options = {}) => {
  return request({
    ...options,
    method: 'delete'
  });
};


export const get = (options = {}) => {
  return request({
    ...options,
    method: 'get'
  });
};
