<template>
  <div class="stat-wrap">
    <div class="header">
      <div class="header-l">
        <span class="title">增值服务使用数据</span>
      </div>
      <div class="header-r">
        <span class="desc">使用时间</span>
        <el-date-picker
          style="width: 240px;"
          v-model="statvalue"
          value-format="YYYY-MM-DD"
          type="daterange"
          range-separator="To"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        />
      </div>
    </div>
    <div class="subheader">
      <div class="subheader-l"><label>总数</label><span>{{total}}</span></div>
      <div class="subheader-r">
        <el-select v-model="statselect1" multiple collapse-tags placeholder="请选择" style="width: 156px">
          <el-option v-for="item in statselect1Options" :disabled="statselect1.length == 1 && statselect1[0] == item.value" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <el-select v-model="statselect2" placeholder="请选择" style="width: 156px">
          <el-option v-for="item in statselect2Options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
    </div>
    <div class="stat-inner-wrap" id="statValueUse"></div>
  </div>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue'
  import * as echarts from 'echarts'
  import moment from 'moment'
  import * as Request from "@/assets/request";
  import * as Utils from "@/assets/utils";

  const statvalue = ref([moment().add(-30, 'day').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
  const statselect1 = ref([])
  const statselect1Options = ref([])
  const statselect2 = ref(null)
  const statselect2Options = ref([])

  const total = ref(0)

  const initFilter = async () => {
    const result = await Request.post({
      url: '/back/stat/getValueAddUseHeader',
      data: {
        dateRange: statvalue.value,
        // companyIds: statselect1.value,
        // serviceType: statselect2.value,
      }
    })

    const companies = []
    if(result?.data?.companies?.length > 0) {
      result.data.companies.map(item => {
        companies.push({ label: item.value, value: item.key })
        statselect1.value.push(item.key)
      })
    }
    statselect1Options.value = companies

    const services = []
    if(result?.data?.services?.length > 0) {
      result.data.services.map(item => {
        services.push({ label: item.value, value: item.key })
      })
      statselect2.value = services[0].value
    }
    statselect2Options.value = services
  }
  onMounted(() => {
    initFilter()
  })
  watch(() => [ statvalue.value ], () => {
    initFilter()
  }, { deep: true })

  const initStat = async () => {
    const result = await Request.post({
      url: '/back/stat/getValueAddUse',
      data: {
        dateRange: statvalue.value,
        companyIds: statselect1.value,
        statType: statselect2.value,
      }
    })
    if(result?.data?.count) {
      total.value = Utils.format_with_substring(result?.data?.count)
    }
    
    const option = {
      xAxis: {
        type: 'category',
        data: []
      },
      yAxis: {
        type: 'value'
      },
      tooltip: {
        trigger: 'axis'
      },
      series: [],
      grid: {
        bottom: '10%',
      }
    };
    const dates = []
    const seriesMap = {}
    if(result?.data?.dates?.length > 0) {
      result?.data?.dates.map(item => {
        dates.push(item.date)
        if(item.items?.length > 0) {
          item.items.map(itm => {
            seriesMap[itm.typeName] = seriesMap[itm.typeName] || []
            seriesMap[itm.typeName].push(itm.count)
          })
        }
      })
    }
    const series = Object.keys(seriesMap).map(name => ({ 
      name,
      // stack: 'Total',
      type: 'line', 
      data: seriesMap[name] 
    }))
    option.xAxis.data = [...dates]
    option.series = [...series]

    echarts.init(document.getElementById('statValueUse')).setOption({...option}, true)
  }
  onMounted(() => {
    initStat()
  })
  watch(() => [ statvalue.value, statselect1.value, statselect2.value ], () => {
    initStat()
  }, { deep: true })
</script>

<style scoped lang="less">
@import "index.less";
</style>