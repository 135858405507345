<template>
  <ListFilter :formList="filterFormList" @search="filterSearchHandle" />

  <el-button type="primary" style="margin-bottom: 16px;" @click="toggleDrawer(true)" :icon="Plus">新增</el-button>
  <el-table border :data="list" style="width: 100%">
    <el-table-column prop="commodityCode" label="商品编码" />
    <el-table-column prop="commodityName" label="商品名称" />
    <el-table-column prop="measureUnit" label="计量单位" />
    <el-table-column prop="exportTaxRate" label="出库退税税率" />
    <el-table-column prop="ciqCode" label="CIQ名称及代码" width="256px">
      <template #default="scope">
        <div style="display: flex; gap: 4px; flex-wrap: wrap">
          <el-tag v-for="(item, index) in scope.row.ciqInfos" :key="index">{{item.name}}:{{item.code}}</el-tag>
        </div>
      </template>
    </el-table-column>

    <el-table-column prop="edit" label="操作" fixed="right">
      <template #default="scope">
        <el-button link type="primary" size="small" @click="editHandle({...scope.row})">编辑</el-button>

        <el-popconfirm title="确定删除?">
          <template #reference>
            <el-button link type="danger" size="small">删除</el-button>
          </template>
          <template #actions="{ confirm, cancel }">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button type="danger" size="small" @click="delHandle(scope.row, confirm)">确定</el-button>
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <Pagination
    :page="page"
    :size="size"
    :total="total"
    @current-change="val => page = val"
  />

  <el-drawer v-model="drawer" direction="rtl" size="555px" :close-on-click-modal="false" :destroy-on-close="true" @close="handleDrawerClose">
    <template #header>
      <h4>编辑</h4>
    </template>
    <template #default>
      <div>
        <el-form :model="form" label-width="auto" style="max-width: 600px">
          <el-form-item label="商品编码" required>
            <el-input v-model="form.commodityCode" size="large" placeholder="请输入商品编码" />
          </el-form-item>
          <el-form-item label="商品名称" required>
            <el-input v-model="form.commodityName" size="large" placeholder="请输入商品名称" />
          </el-form-item>
          <el-form-item label="计量单位" required>
            <el-input v-model="form.measureUnit" size="large" placeholder="请输入计量单位" />
          </el-form-item>
          <el-form-item label="出库退税税率" required>
            <el-input v-model="form.exportTaxRate" size="large" placeholder="请输入出库退税税率" />
          </el-form-item>
          <el-form-item label="CIQ名称及代码" required>
            <el-row :gutter="8" v-for="(item, index) in ciqList" :key="index" style="margin-bottom: 16px; align-items: center">
              <el-col :span="10"><el-input v-model="form['ciqInfos-name-' + item.id]" size="large" placeholder="请输入CIQ名称" /></el-col>
              <el-col :span="10"><el-input v-model="form['ciqInfos-code-' + item.id]" size="large" placeholder="请输入CIQ代码" /></el-col>
              <el-col :span="4">
                <el-row :gutter="8">
                  <el-col :span="12"><el-icon @click="ctrlCIQ('add', index)"><CirclePlusFilled /></el-icon></el-col>
                  <el-col :span="12"><el-icon @click="ctrlCIQ('minus', index)"><RemoveFilled :style="{color: ciqList.length > 1 ? '#f56c6c' : '#f2caca', cursor: ciqList.length > 1 ? 'auto' : 'not-allowed'}" /></el-icon></el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="toggleDrawer(false)">取消</el-button>
        <el-button type="primary" @click="submitHandle" :disabled="(!form.commodityCode || !form.commodityName || !form.measureUnit || !form.exportTaxRate || isCiqInfosEmpty)" :loading="loading">保存</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script setup>
  import {onMounted, ref, watch} from 'vue'
  import * as Request from "@/assets/request";
  import {ElMessage} from "element-plus";
  import { uid } from 'uid';
  import ListFilter from '@/components/listFliter'
  import Pagination from '@/components/spagination'
  import { Plus, CirclePlusFilled, RemoveFilled } from '@element-plus/icons-vue'

  const list = ref([])
  const listQuery = ref({})
  const total = ref(0)
  const size = ref(50)
  const page = ref(1)
  const getList = async () => {
    const result = await Request.post({
      url: '/hsCode/managerQuery',
      data: {
        ...listQuery.value,
        page: page.value,
        size: size.value
      }
    })
    if(result?.status === 200) {
      list.value = result.data.list;
      total.value = result.data.total;
    }
  }
  onMounted(() => {
    getList()
  })
  watch(() => [ listQuery.value, page.value ], () => {
    getList();
  }, { deep: true })

  const drawer = ref(false)
  const handleDrawerClose = () => {
    form.value = {}
    ciqList.value = [{ id: uid() }]
  }
  const toggleDrawer = (flag) => {
    drawer.value = flag
  }

  const ciqList = ref([{ id: uid() }])
  const ctrlCIQ = (method, index) => {
    if(method === 'add') {
      ciqList.value.push({ id: uid() })
    }
    if(method === 'minus') {
      if(ciqList.value.length == 1) return

      ciqList.value.splice(index, 1)
    }
  }

  const form = ref({
    // id: null,
  })

  const isCiqInfosEmpty = ref(true)
  watch(() => [form.value, ciqList.value], () => {
    const ciqInfosListMap = {}
    for(let key in form.value) {
      if(key.startsWith('ciqInfos-')) {
        const [ prefix, field, id ] = key.split('-')
        console.log(prefix, field)
        const ciqInfosCode = form.value[`ciqInfos-code-${id}`]
        const ciqInfosName = form.value[`ciqInfos-name-${id}`]
        ciqInfosListMap[id] = {
          code: ciqInfosCode, name: ciqInfosName
        }
      }
    }
    const ciqInfosList = Object.keys(ciqInfosListMap).map(key => ciqInfosListMap[key])
    
    if(ciqInfosList.length != ciqList.value.length) {
      isCiqInfosEmpty.value = true
      return
    }
    for(let i = 0, len = ciqInfosList.length; i < len; i++) {
      const item = ciqInfosList[i]
      if(!item.code || !item.name) {
        isCiqInfosEmpty.value = true
        break
      }else{
        isCiqInfosEmpty.value = false
      }
    }
  }, { deep: true })

  const editHandle = (item) => {
    toggleDrawer(true)
    form.value = item
    if(form.value?.ciqInfos?.length > 0) {
      form.value.ciqInfos.map((item, index) => {
        const id = uid()
        ciqList.value[index] = { ...item, id }

        form.value['ciqInfos-name-' + id] = item.name;
        form.value['ciqInfos-code-' + id] = item.code;

      })
    }
  }
  const loading = ref(false)
  const submitHandle = async () => {
    const _formObject = {}
    const ciqInfos = {}
    for(let key in form.value) {
      const res = form.value[key]
      if(key.startsWith('ciqInfos-')) {
        const [ prefix, field, id ] = key.split('-')
        console.log(prefix)
        ciqList.value.map(itm => {
          if(itm.id === id) {
            ciqInfos[id] = ciqInfos[id] || {}
            ciqInfos[id][field] = res
          }
        })
      }else{
        _formObject[key] = res
      }
    }
    _formObject.ciqInfos = Object.values(ciqInfos)

    let url = '/hsCode/add';
    if(form.value?.id){
      url = '/hsCode/update';
      _formObject.id = form.value.id
    }
    loading.value = true
    const result = await Request.post({
      url,
      data: _formObject
    })
    loading.value = false
    if(result?.status === 200) {
      ElMessage.success('保存成功');
      toggleDrawer(false)
      getList()
      form.value = {}
      ciqList.value = [{ id: uid() }]
    }
  }
  const delHandle = async (data, callback) => {
    const result = await Request.del({
      url: `/hsCode/delete/${data.id}`
    })
    if(result?.status === 200) {
      ElMessage.success('操作成功')

      callback()
      getList()
    }
  }

  /**
   * filter
   * @type {Ref<UnwrapRef<*[]>, UnwrapRef<*[]> | *[]>}
   */
  const filterFormList = ref([])
  const filterFormListInit = () => {
    filterFormList.value = [
      {
        type: 'input', field: 'code',
        label: '商品编码', placeholder: '请输入',
      },
      {
        type: 'input', field: 'name',
        label: '商品名称', placeholder: '请输入',
      },
    ]
  }
  onMounted(() => {
    filterFormListInit()
  })
  const filterSearchHandle = (formValue) => {
    listQuery.value = { ...formValue }
    page.value = 1
  }
</script>

<style scoped lang="less">
@import "index.less";
</style>
