<template>
  <el-button type="primary" style="margin-bottom: 16px;" @click="toggleDrawer(true)">新增</el-button>
  <el-table border :data="list" style="width: 100%">
    <el-table-column prop="seq" label="排序" />
    <el-table-column prop="img" label="图片">
      <template #default="scope">
        <img :src="scope.row.imgUrl" style="height: 80px;" />
      </template>
    </el-table-column>
    <el-table-column prop="title" label="标题" />
    <el-table-column prop="content" label="文字">
      <template #default="scope">
        <span style="display:inline-block; max-height: 88px; overflow: auto;">{{ scope.row.content }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="edit" label="操作" fixed="right">
      <template #default="scope">
        <el-button link type="primary" size="small" @click="editHandle({...scope.row})">编辑</el-button>

        <el-popconfirm title="确定删除?">
          <template #reference>
            <el-button link type="danger" size="small">删除</el-button>
          </template>
          <template #actions="{ confirm, cancel }">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button type="danger" size="small" @click="delHandle(scope.row, confirm)">确定</el-button>
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>

  <el-drawer v-model="drawer" direction="rtl" size="888px" :close-on-click-modal="false" :destroy-on-close="true" @close="handleDrawerClose">
    <template #header>
      <h4>编辑</h4>
    </template>
    <template #default>
      <div>
        <el-form :model="form" label-width="auto" style="max-width: 600px">
          <el-form-item label="图片" class="banner-upload-wrap" required>
            <el-upload :headers="uploadHeader" v-loading="uploadFlag" drag :action="`${Request.baseURL}/file/upload`" :show-file-list="false" class="banner-upload-body" :class="{hide: form.imgUrl}" :before-upload="() => uploadFlag = true" :on-success="handleSuccess">
              <el-icon class="el-icon--upload"><UploadFilled /></el-icon>
              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
              <template #tip>
                <el-text type="warning">建议上传图片不超过1M</el-text>
              </template>
            </el-upload>
            <div class="banner-upload-preview" v-if="form.imgUrl">
              <template v-if="fileType === 'video'">
                <video autoplay muted loop :style="{ width: '100%', height: '100%', objectFit: 'fill' }">
                  <source :src="form.imgUrl" type="video/mp4" />
                  <span>Your browser does not support HTML video.</span>
                </video>
              </template>
              <template v-if="fileType === 'img'"><img :src="form.imgUrl" /></template>
            </div>
          </el-form-item>

          <el-form-item label="标题" name="title" required>
            <el-input v-model="form.title" size="large" placeholder="请输入标题内容" />
          </el-form-item>

          <el-form-item label="文字" name="content" required>
            <el-input v-model="form.content" type="textarea" size="large" :rows="4" placeholder="请输入文字内容" />
          </el-form-item>

          <el-form-item label="排序（越小越靠前）" required>
            <el-input v-model="form.seq" type="number" size="large" placeholder="请输入排序：10" />
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="toggleDrawer(false)">取消</el-button>
        <el-button type="primary" @click="submitHandle" :disabled="(form.seq == '' || !form.imgUrl || !form.title || !form.content)" :loading="loading">保存</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script setup>
  import {onMounted, ref} from 'vue'
  import * as Request from "@/assets/request";
  import * as Utils from "@/assets/utils";
  import {ElMessage} from "element-plus";
  import { UploadFilled } from '@element-plus/icons-vue'

  const list = ref([])

  const getList = async () => {
    const result = await Request.post({
      url: '/product/query',
      data: {}
    })
    if(result?.status === 200) {
      list.value = result.data.list;
    }
  }
  onMounted(() => {
    getList()
  })

  const drawer = ref(false)
  const handleDrawerClose = () => {
    form.value = {
      // id: null,
      seq: '',
      imgUrl: '',
      title: '',
      content: '',
    }
  }
  const form = ref({
    // id: null,
    seq: '',
    imgUrl: '',
    title: '',
    content: '',
  })
  const fileType = ref('img')
  // 上传
  const uploadHeader = ref({
    Authorization: Utils.user.getToken()
  })
  const uploadFlag = ref(false)
  const handleSuccess = (result) => {
    uploadFlag.value = false
    if(result?.status === 200) {
      form.value.imgUrl = result.data
      fileType.value = Utils.getFileType(result.data)
    }else{
      ElMessage.error(result?.msg)
    }
  }

  const loading = ref(false)

  const toggleDrawer = (flag) => {
    drawer.value = flag
  }
  const editHandle = (item) => {
    toggleDrawer(true)
    form.value = item
  }
  const submitHandle = async () => {
    let url = '/product/add';
    if(form.value?.id){
      url = '/product/update';
    }
    loading.value = true
    const result = await Request.post({
      url,
      data: form.value
    })
    loading.value = false
    if(result?.status === 200) {
      ElMessage.success('保存成功');
      toggleDrawer(false)
      getList()
      form.value = {
        // id: null,
        seq: '',
        imgUrl: '',
        title: '',
        content: '',
      }
    }
  }
  const delHandle = async (data, callback) => {
    const result = await Request.del({
      url: `/product/delete/${data.id}`
    })
    if(result?.status === 200) {
      ElMessage.success('操作成功')

      callback()
      getList()
    }
  }
</script>

<style scoped lang="less">
@import "index.less";
</style>
