<template>
  <el-form :model="form" label-width="auto" style="max-width: 600px">
    <el-form-item label="Banner 图片/视频" class="banner-upload-wrap" required>
      <el-upload drag :action="`${Request.baseURL}/file/upload`" v-loading="uploadFlag" :headers="uploadHeader" :show-file-list="false" class="banner-upload-body" :class="{hide: form.videoUrl}" :before-upload="() => uploadFlag = true" :on-success="handleSuccess">
        <el-icon class="el-icon--upload"><UploadFilled /></el-icon>
        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
        <template #tip>
          <div class="el-upload__tip">为了更加流畅播放，建议上传视频不超过3M</div>
        </template>
      </el-upload>
      <div class="banner-upload-preview" v-if="form.videoUrl">
        <template v-if="fileType === 'video'">
          <video autoplay muted loop :style="{ width: '100%', height: '100%', objectFit: 'fill' }">
            <source :src="form.videoUrl" type="video/mp4" />
            <span>Your browser does not support HTML video.</span>
          </video>
        </template>
        <template v-if="fileType === 'img'"><img :src="form.videoUrl" /></template>
      </div>
    </el-form-item>

    <el-form-item label="Banner 主题">
      <el-input v-model="form.title" size="large" placeholder="请输入主题内容" />
    </el-form-item>

    <el-form-item label="Banner 描述">
      <el-input v-model="form.content" type="textarea" size="large" :rows="4" placeholder="请输入描述内容" />
    </el-form-item>

    <el-form-item label=" ">
      <el-button type="primary" size="large" :disabled="!form.videoUrl" @click="onSubmit" :loading="loading">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
  import {onMounted, ref} from 'vue'
  import * as Request from '@/assets/request'
  import * as Utils from '@/assets/utils'
  import { UploadFilled } from '@element-plus/icons-vue'
  import { ElMessage } from 'element-plus'

  const form = ref({
    id: null,
    videoUrl: '',
    title: '',
    content: '',
  })

  const fileType = ref('video')

  const getBanner = async () => {
    const result = await Request.post({
      url: '/banner/query',
      data: {}
    })
    if(result?.status === 200) {
      form.value = result.data;
    }

    // setTimeout(() => {
    //   console.log('jinlaile-----')
    //   form.value.videoUrl = ''
    //   setTimeout(() => {
    //     form.value.videoUrl = 'https://geekfiles.oss-cn-shanghai.aliyuncs.com/sigmash/1733411602346Screen Recording 2024-12-05 at 22.11.08.mov'
    //   }, 888)
    // }, 2000)
    // console.log('test-----', form.value.videoUrl)

    const _fileType = Utils.getFileType(form.value.videoUrl)
    if(_fileType){
      fileType.value = _fileType;
    }
  }
  onMounted(() => {
    getBanner()
  })

  // 上传
  const uploadHeader = ref({
    Authorization: Utils.user.getToken()
  })
  const uploadFlag = ref(false)
  const handleSuccess = (result) => {
    uploadFlag.value = false
    if(result?.status === 200) {
      form.value.videoUrl = '';
      setTimeout(() => {
        form.value.videoUrl = result.data
        fileType.value = Utils.getFileType(result.data)
      }, 222)
    }else{
      ElMessage.error(result?.msg)
    }
  }

  // 提交表单
  const loading = ref(false)
  const onSubmit = async () => {
    if(!form.value.videoUrl){
      return ElMessage.error('请上传视频');
    }

    let url = '/banner/add';
    if(form.value?.id){
      url = '/banner/update';
    }
    loading.value = true
    const result = await Request.post({
      url,
      data: form.value
    })
    loading.value = false
    if(result?.status === 200) {
      ElMessage.success('保存成功');
    }
  }
</script>

<style scoped lang="less">
@import "index.less";
</style>
