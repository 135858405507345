<template>
  <el-button type="primary" style="margin-bottom: 16px;" @click="toggleDrawer(true)" :icon="Plus">新增</el-button>
  <el-table border :data="list" style="width: 100%" @selection-change="handleSelectionChange">
    <el-table-column prop="name" label="标签名称" />
    <el-table-column prop="status" label="状态">
      <template #default="scope">
        <el-tag :type="({ 1: 'success', 2: 'danger' })[scope.row.status]">{{({ 0: '全部', 1: '启用', 2: '禁用' })[scope.row.status]}}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="edit" label="操作" fixed="right">
      <template #default="scope">
        <el-button link type="primary" size="small" @click="editHandle({...scope.row})">编辑</el-button>

        <el-popconfirm :title="`确定${({ 1: '禁用', 2: '启用' })[scope.row.status]}?`">
          <template #reference>
            <el-button link :type="({ 1: 'danger', 2: 'default' })[scope.row.status]" size="small">{{ ({ 1: '禁用', 2: '启用' })[scope.row.status] }}</el-button>
          </template>
          <template #actions="{ confirm, cancel }">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button type="danger" size="small" @click="forbidToggleHandle([scope.row.id], ({ 1: 2, 2: 1 })[scope.row.status], confirm)">确定</el-button>
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>

  <el-drawer v-model="drawer" direction="rtl" size="555px" :close-on-click-modal="false" :destroy-on-close="true" @close="handleDrawerClose">
    <template #header>
      <h4>编辑</h4>
    </template>
    <template #default>
      <div>
        <el-form :model="form" label-width="auto" style="max-width: 600px">
          <el-form-item label="标签名称">
            <el-input v-model="form.name" size="large" placeholder="请输入标签名称" />
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="form.type" size="large" placeholder="请选择标签类型">
              <el-option
                v-for="item in [{ label: '企业', value: 1 }]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="toggleDrawer(false)">取消</el-button>
        <el-button type="primary" @click="submitClick" :disabled="(!form.name || !form.type)">保存</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script setup>
  import {onMounted, ref} from 'vue'
  import * as Request from "@/assets/request";
  import {ElMessage} from "element-plus";
  import { Plus } from '@element-plus/icons-vue'

  const list = ref([])
  const type = ref(1)
  const status = ref(0)
  const getList = async () => {
    const result = await Request.get({
      url: `/tag/getTag/${type.value}/${status.value}`,
      data: {}
    })
    if(result?.status === 200) {
      list.value = result.data;
    }
  }
  onMounted(() => {
    getList()
  })

  const drawer = ref(false)
  const handleDrawerClose = () => {
    form.value = {}
  }
  const form = ref({
    // id: null,
    type: 1,
    status: 1,
    name: '',
  })

  const toggleDrawer = (flag) => {
    drawer.value = flag
  }
  const editHandle = (item) => {
    toggleDrawer(true)
    form.value = item
    form.value.type = type.value
  }
  const submitClick = async () => {
    let url = '/tag/add';
    if(form.value?.id){
      url = '/tag/update';
    }
    const result = await Request.post({
      url,
      data: form.value
    })
    if(result?.status === 200) {
      ElMessage.success('保存成功');
      toggleDrawer(false)
      getList()
      form.value = {
        // id: null,
        type: null,
        name: '',
      }
    }
  }

  const forbidToggleHandle = async (ids, target, callback) => {
    const url = ({ 2: '/tag/disable', 1: '/tag/enable' })[target]
    const result = await Request.post({
      url,
      data: ids
    })
    if(result?.status === 200) {
      ElMessage.success('保存成功');

      if(typeof callback === 'function'){
        callback()
      }
      getList()
      form.value = {
        // id: null,
        type: 1,
        status: 1,
        name: '',
      }
    }
  }

  const multipleSelection = ref([])
  const handleSelectionChange = (val) => {
    multipleSelection.value = val.map(v => v.id)
  }
</script>

<style scoped lang="less">
@import "index.less";
</style>
