<template>
  <el-tabs type="border-card" v-model="tabName" class="hometab-contentwrap">
    <el-tab-pane label="Banner 管理" name="banner">
      <Banner />
    </el-tab-pane>
    <el-tab-pane label="产品介绍" name="product">
      <Intro />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import {ref} from 'vue'

import Banner from './components/banner'
import Intro from './components/intro'

const tabName = ref('banner')
</script>

<style scoped lang="less">
@import "index.less";
</style>
