<template>
  <ListFilter :formList="filterFormList" @search="filterSearchHandle" />

  <el-button type="primary" style="margin-bottom: 16px;" @click="toggleDrawer(true)" :icon="Plus">新增</el-button>
  <el-button type="danger" style="margin-bottom: 16px;" @click="forbidToggleHandle(multipleSelection, 2)" :disabled="multipleSelection.length === 0">批量禁用</el-button>
  <el-button type="default" style="margin-bottom: 16px;" @click="forbidToggleHandle(multipleSelection, 1)" :disabled="multipleSelection.length === 0">批量启用</el-button>
  <el-button type="default" style="margin-bottom: 16px;" @click="multiSettingToggleHandle(true)" :disabled="multipleSelection.length === 0">批量设置角色</el-button>
  <el-table border :data="list" style="width: 100%" @selection-change="handleSelectionChange">
    <el-table-column type="selection" width="55" />
    <el-table-column prop="id" label="用户ID" />
    <el-table-column prop="username" label="姓名" />
    <el-table-column prop="phone" label="手机号" />
    <el-table-column prop="email" label="邮箱" />
    <el-table-column prop="roleName" label="角色" />
    <el-table-column prop="companyName" label="公司名称" />
    <el-table-column prop="status" label="状态">
      <template #default="scope">
        <el-tag :type="({ 1: 'success', 2: 'danger' })[scope.row.status]">{{({ 1: '启用', 2: '禁用' })[scope.row.status]}}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="edit" label="操作" fixed="right">
      <template #default="scope">
        <el-button link type="primary" size="small" @click="editHandle({...scope.row})">编辑</el-button>

        <el-popconfirm :title="`确定${({ 1: '禁用', 2: '启用' })[scope.row.status]}?`">
          <template #reference>
            <el-button link :type="({ 1: 'danger', 2: 'default' })[scope.row.status]" size="small">{{ ({ 1: '禁用', 2: '启用' })[scope.row.status] }}</el-button>
          </template>
          <template #actions="{ confirm, cancel }">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button type="danger" size="small" @click="forbidToggleHandle([scope.row.id], ({ 1: 2, 2: 1 })[scope.row.status], confirm)">确定</el-button>
          </template>
        </el-popconfirm>

        <el-popconfirm title="确定删除?">
          <template #reference>
            <el-button link type="danger" size="small">删除</el-button>
          </template>
          <template #actions="{ confirm, cancel }">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button type="danger" size="small" @click="delHandle(scope.row, confirm)">确定</el-button>
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <Pagination
    :page="page"
    :size="size"
    :total="total"
    @current-change="val => page = val"
  />

  <el-drawer v-model="drawer" direction="rtl" size="555px" :close-on-click-modal="false" :destroy-on-close="true" @close="handleDrawerClose">
    <template #header>
      <h4>编辑</h4>
    </template>
    <template #default>
      <div>
        <el-form :model="form" label-width="auto" style="max-width: 600px">
          <el-form-item label="姓名" required>
            <el-input v-model="form.username" size="large" placeholder="请输入姓名" />
          </el-form-item>
          <el-form-item label="手机号" required>
            <el-input v-model="form.phone" size="large" placeholder="请输入手机号" maxlength="11" />
            <el-text type="warning" slot="extra">手机号将作为登录账号，请谨慎设置</el-text>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="form.email" size="large" placeholder="请输入邮箱" type="email" />
          </el-form-item>
          <el-form-item label="密码" required v-if="!form?.id">
            <el-input v-model="form.password" autocomplete="new-password" show-password size="large" type="password" placeholder="请输入密码" />
          </el-form-item>
          <el-form-item label="角色" required>
            <el-select v-model="form.role" size="large" placeholder="请选择角色">
              <el-option
                v-for="item in rolesList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="公司" required>
            <el-select v-model="form.companyId" size="large" placeholder="请选择公司">
              <el-option
                v-for="item in companyList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="toggleDrawer(false)">取消</el-button>
        <el-button type="primary" @click="submitHandle" :disabled="(!form.username || !form.phone || !form.password || !form.role || !form.companyId)" :loading="loading">保存</el-button>
      </div>
    </template>
  </el-drawer>

  <el-dialog
    v-model="dialogVisible"
    title="批量设置用户信息"
    width="568"
  >
    <el-form :model="multiForm" label-width="auto" style="max-width: 600px">
      <el-form-item label="角色" required>
        <el-select v-model="multiForm.role" size="large" placeholder="请选择角色">
          <el-option
            v-for="item in rolesList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="multiSettingRoleHandle">保存</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import * as Request from "@/assets/request";
import {ElMessage} from "element-plus";
import ListFilter from '@/components/listFliter'
import Pagination from '@/components/spagination'
import { Plus } from '@element-plus/icons-vue'

const list = ref([])
const listQuery = ref({})
const total = ref(0)
const size = ref(50)
const page = ref(1)
const getList = async () => {
  const result = await Request.post({
    url: '/user/search',
    data: {
      ...listQuery.value,
      page: page.value,
      size: size.value
    }
  })
  if(result?.status === 200) {
    list.value = result.data.list;
    total.value = result.data.total;
  }
}
onMounted(() => {
  getList()
})
watch(() => [ listQuery.value, page.value ], () => {
  getList();
}, { deep: true })

const rolesList = ref([
  { label: '普通用户', value: 30 },
  { label: '客户管理员', value: 70 },
  { label: '超级管理员', value: 90 },
])

const titleList = ref([])
const getTitleList = async () => {
  const result = await Request.post({
    url: '/company/queryTitle',
    data: {}
  })
  if(result?.status === 200) {
    titleList.value = result.data.list;
  }
}
onMounted(() => {
  getTitleList()
})

const companyList = ref([])
const getCompanyList = async () => {
  const result = await Request.post({
    url: '/company/query',
    data: {}
  })
  if(result?.status === 200) {
    companyList.value = result.data.list;
  }
}
onMounted(() => {
  getCompanyList()
})

const delHandle = async (data, callback) => {
  const result = await Request.del({
    url: `/user/delete/${data.id}`
  })
  if(result?.status === 200) {
    ElMessage.success('操作成功')

    callback()
    getList()
  }
}

const drawer = ref(false)
const handleDrawerClose = () => {
    form.value = {}
  }
const form = ref({
  // id: null,
  username: '',
  phone: '',
  email: '',
  password: '',
  role: null,
  companyId: null,
})
const toggleDrawer = (flag) => {
  drawer.value = flag
}
const editHandle = (item) => {
  toggleDrawer(true)
  item.role = item.role || item.roleId
  console.log('item', item)
  form.value = item
}
const loading = ref(false)
const submitHandle = async () => {
  const _data = {...form.value}
  let url = '/user/add';
  if(_data?.id){
    url = '/user/update';
    delete _data.password
  }
  loading.value = true
  const result = await Request.post({
    url,
    data: _data
  })
  loading.value = false
  if(result?.status === 200) {
    ElMessage.success('保存成功');
    toggleDrawer(false)
    getList()
    form.value = {
      // id: null,
      username: '',
      phone: '',
      email: '',
      password: '',
      role: null,
      companyId: null,
    }
  }
}

const forbidToggleHandle = async (ids, target, callback) => {
  const url = ({ 2: '/user/disable', 1: '/user/enable' })[target]
  const result = await Request.post({
    url,
    data: ids
  })
  if(result?.status === 200) {
    ElMessage.success('保存成功');

    if(typeof callback === 'function'){
      callback()
    }
    getList()
    form.value = {
      // id: null,
      type: 1,
      status: 1,
      name: '',
    }
    multipleSelection.value = []
  }
}

const multipleSelection = ref([])
const handleSelectionChange = (val) => {
  multipleSelection.value = val.map(v => v.id)
}

const multiForm = ref({
  role: null
})
const dialogVisible = ref(false)
const multiSettingToggleHandle = (flag) => {
  dialogVisible.value = flag
}
const multiSettingRoleHandle = async () => {
  const ids = multipleSelection.value
  const role = multiForm.value.role

  const result = await Request.post({
    url: '/user/assignRole',
    data: { ids, role }
  })
  if(result?.status === 200) {
    ElMessage.success('保存成功');
    multiSettingToggleHandle(false)
    getList()

    multiForm.value = {
      role: null
    }
    multipleSelection.value = []
  }
}

/**
 * filter
 * @type {Ref<UnwrapRef<*[]>, UnwrapRef<*[]> | *[]>}
 */
const filterFormList = ref([])
const filterFormListInit = () => {
  filterFormList.value = [
    {
      type: 'input', field: 'fuzzyName',
      label: '姓名', placeholder: '请输入',
    },
    {
      type: 'input', field: 'phone',
      label: '手机号', placeholder: '请输入',
    },
    {
      type: 'select', field: 'companyId',
      label: '公司名称', placeholder: '请选择',
      options: companyList.value?.map(item => ({ value: item.id, label: item.name })),
      style: "width: 156px;"
    }
  ]
}
onMounted(() => {
  filterFormListInit()
})
watch(() => companyList.value, () => {
  filterFormListInit()
})
const filterSearchHandle = (formValue) => {
  listQuery.value = {...formValue}
  page.value = 1
}
</script>

<style scoped lang="less">
@import "index.less";
</style>
