<template>
  <el-button type="primary" style="margin-bottom: 16px;" @click="toggleDrawer(true)" :icon="Plus">新增</el-button>
  <el-table border :data="list" style="width: 100%">
    <el-table-column prop="containerImg" label="图片">
      <template #default="scope">
        <img :src="scope.row.containerImg" style="height: 80px;" />
      </template>
    </el-table-column>
    <el-table-column prop="containerTypeCn" label="箱型中文" />
    <el-table-column prop="containerTypeEn" label="箱型英文" />
    <el-table-column prop="containerSize" label="箱体尺寸" />
    <el-table-column prop="containerTemp" label="箱体温度" />
    <el-table-column prop="portType" label="箱体内部尺寸（单位mm）">
      <template #default="scope">
        <span>长：{{scope.row.internalSizeL}} / 宽：{{scope.row.internalSizeW}} / 高：{{scope.row.internalSizeH}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="portType" label="门宽高（单位mm）">
      <template #default="scope">
        <span>宽：{{scope.row.doorWidthW}} / 高：{{scope.row.doorWidthH}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="grossWeight" label="毛重载（单位kgs）" />
    <el-table-column prop="deadWeight" label="载体重（单位kgs）" />
    <el-table-column prop="volume" label="体积（单位cbm）" />
    <el-table-column prop="edit" label="操作" fixed="right">
      <template #default="scope">
        <el-button link type="primary" size="small" @click="editHandle({...scope.row})">编辑</el-button>

        <el-popconfirm title="确定删除?">
          <template #reference>
            <el-button link type="danger" size="small">删除</el-button>
          </template>
          <template #actions="{ confirm, cancel }">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button type="danger" size="small" @click="delHandle(scope.row, confirm)">确定</el-button>
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <Pagination
    :page="page"
    :size="size"
    :total="total"
    @current-change="val => page = val"
  />

  <el-drawer v-model="drawer" direction="rtl" size="888px" :close-on-click-modal="false" :destroy-on-close="true" @close="handleDrawerClose">
    <template #header>
      <h4>编辑</h4>
    </template>
    <template #default>
      <div>
        <el-form :model="form" label-width="auto" style="max-width: 726px">
          <el-form-item label="箱型图片" class="banner-upload-wrap" required>
            <el-upload :headers="uploadHeader" drag :action="`${Request.baseURL}/file/upload`" :show-file-list="false" class="banner-upload-body" :class="{hide: form.containerImg}" :on-success="handleSuccess">
              <el-icon class="el-icon--upload"><UploadFilled /></el-icon>
              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
              <template #tip>
                <el-text type="warning">建议上传图片不超过1M</el-text>
              </template>
            </el-upload>
            <div class="banner-upload-preview" v-if="form.containerImg">
              <template v-if="fileType === 'video'">
                <video autoplay muted loop :style="{ width: '100%', height: '100%', objectFit: 'fill' }">
                  <source :src="form.containerImg" type="video/mp4" />
                  <span>Your browser does not support HTML video.</span>
                </video>
              </template>
              <template v-if="fileType === 'img'"><img :src="form.containerImg" /></template>
            </div>
          </el-form-item>
          <el-form-item label="箱型中文" required>
            <el-input v-model="form.containerTypeCn" size="large" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="箱型英文" required>
            <el-input v-model="form.containerTypeEn" size="large" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="箱体尺寸" required>
            <el-input v-model="form.containerSize" size="large" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="箱体温度">
            <el-input v-model="form.containerTemp" size="large" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="箱体内部尺寸（单位mm）" required>
            <el-row :gutter="8">
              <el-col :span="8">
                <el-form-item label="长" class="container-size-form-item">
                  <el-input v-model="form.internalSizeL" size="large" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="宽" class="container-size-form-item">
                  <el-input v-model="form.internalSizeW" size="large" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="高" class="container-size-form-item">
                  <el-input v-model="form.internalSizeH" size="large" placeholder="请输入" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="门宽高（单位mm）" required>
            <el-row :gutter="8">
              <el-col :span="11">
                <el-form-item label="宽" class="container-size-form-item">
                  <el-input v-model="form.doorWidthW" size="large" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="高" class="container-size-form-item">
                  <el-input v-model="form.doorWidthH" size="large" placeholder="请输入" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="毛重载（单位kgs）" required>
            <el-input v-model="form.grossWeight" size="large" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="载体重（单位kgs）" required>
            <el-input v-model="form.deadWeight" size="large" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="体积（单位cbm）" required>
            <el-input v-model="form.volume" size="large" placeholder="请输入" />
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="toggleDrawer(false)">取消</el-button>
        <el-button type="primary" @click="submitHandle" :disabled="(!containerImg || !form.containerTypeCn || !form.containerTypeEn || !form.containerSize || !form.internalSizeL || !form.internalSizeL || !form.internalSizeW || !form.internalSizeH || !form.doorWidthW || !form.doorWidthH || !form.grossWeight || !form.deadWeight || !form.volume)" :loading="loading">保存</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
  import * as Request from "@/assets/request";
  import {ElMessage} from "element-plus";
  import { Plus } from '@element-plus/icons-vue'
  import * as Utils from "@/assets/utils";
  import { UploadFilled } from '@element-plus/icons-vue'
  import Pagination from '@/components/spagination'

  const list = ref([])
  const listQuery = ref({})
  const total = ref(0)
  const size = ref(50)
  const page = ref(1)
  const getList = async () => {
    const result = await Request.post({
      url: '/container/size/managerQuery',
      data: {
        ...listQuery.value,
        page: page.value,
        size: size.value
      }
    })
    if(result?.status === 200) {
      list.value = result.data.list;
      total.value = result.data.total;
    }
  }
  onMounted(() => {
    getList()
  })
  watch(() => [ listQuery.value, page.value ], () => {
    getList();
  }, { deep: true })

  const drawer = ref(false)
  const handleDrawerClose = () => {
    form.value = {}
  }
  const toggleDrawer = (flag) => {
    drawer.value = flag
  }

  const form = ref({
    // id: null,
  })

  const fileType = ref('img')
  // 上传
  const uploadHeader = ref({
    Authorization: Utils.user.getToken()
  })
  const handleSuccess = (result) => {
    if(result?.status === 200) {
      form.value.containerImg = result.data
      fileType.value = Utils.getFileType(result.data)
    }else{
      ElMessage.error(result?.msg)
    }
  }

  const editHandle = (item) => {
    toggleDrawer(true)
    form.value = item
  }
  const loading = ref(false)
  const submitHandle = async () => {
    console.log('form.value', form.value)

    let url = '/container/size/add';
    if(form.value?.id){
      url = '/container/size/update';
    }
    loading.value = true
    const result = await Request.post({
      url,
      data: form.value
    })
    loading.value = false
    console.log('result---', result)
    if(result?.status === 200) {
      ElMessage.success('保存成功');
      toggleDrawer(false)
      getList()
      form.value = {
        // id: null,
      }
    }
  }
  const delHandle = async (data, callback) => {
    const result = await Request.del({
      url: `/container/size/delete/${data.id}`
    })
    if(result?.status === 200) {
      ElMessage.success('操作成功')

      callback()
      getList()
    }
  }
</script>

<style scoped lang="less">
@import "index.less";
</style>
