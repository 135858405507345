<template>
  <section class="page-left-side">
    <el-menu
      :default-active="$route.path"
      v-for="(item, index) in menuList"
      :key="index"
      router
    >
      <el-sub-menu :index="'' + index" v-if="item?.children?.length > 0">
        <template #title>
          <el-icon><component :is="item.icon"/></el-icon>
          <span>{{ item.title }}</span>
        </template>
        <template v-if="item.children">
          <el-menu-item v-for="(_item, _index) in item.children" :key="_index" :index="_item.path" :disabled="_item.disabled">{{ _item.title }}</el-menu-item>
        </template>
      </el-sub-menu>
      <el-menu-item :index="item.path" :disabled="item.disabled" v-else>
        <template #title>
          <el-icon><component :is="item.icon"/></el-icon>
          <span>{{ item.title }}</span>
        </template>
      </el-menu-item>
    </el-menu>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import {
  Menu,
  Setting,
  House,
  DataBoard,
  User,
} from '@element-plus/icons-vue'

const menuList = ref([
  {
    title: '首页管理', path: '/home', icon: House
  },
  {
    title: '物流字典', icon: Menu,
    children: [
      { title: '全球港口', path: '/ports' },
      { title: 'HS CODE', path: '/hscode' },
      { title: '集装箱尺寸', path: '/container/size' },
    ]
  },
  {
    title: '账号管理', icon: User,
    children: [
      { title: '企业账号', path: '/account/enterprise' },
      { title: '用户账号', path: '/account/user' },
    ]
  },
  {
    title: '数据统计', path: '/stat', icon: DataBoard,
  },
  {
    title: '系统设置', icon: Setting,
    children: [
      { title: '标签管理', path: '/tags' }
    ]
  },
])

</script>

<style scoped lang="less">
  @import "index.less";
</style>
