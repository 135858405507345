<template>
  <div class="list-filter">
    <el-form :model="form" label-width="auto" inline class="list-filter-form">
      <el-form-item :label="item.label" v-for="(item, index) in _formList" :key="index">
        <!-- input -->
        <template v-if="item.type === 'input'">
          <el-input v-model="form[item.field]" :placeholder="item.placeholder" clearable />
        </template>
        <!-- select -->
        <template v-if="item.type === 'select'">
          <el-select v-model="form[item.field]" :multiple="item.multiple" :placeholder="item.placeholder" :style="item.style" clearable>
            <el-option
              v-for="(itm, idx) in item.options"
              :label="itm.label" :value="itm.value"
              :key="idx"
            />
          </el-select>
        </template>
      </el-form-item>
    </el-form>
    <div class="list-filter-btns">
      <el-button type="primary" @click="searchHandle" :icon="Search">查询</el-button>
      <el-button @click="resetHandle">重置</el-button>
    </div>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue'
import { Search } from '@element-plus/icons-vue'
import {onMounted} from "vue";

const form = ref({})

// eslint-disable-next-line no-undef
const props = defineProps({
  formList: {
    type: Array,
    default: () => []
  }
})

// eslint-disable-next-line no-undef
const emit = defineEmits([ 'search' ])

const _formList = ref([])

const init = () => {
  console.log('props.formList',props.formList)
  _formList.value = props.formList
}
onMounted(() => {
  init()
})
watch(() => props.formList, () => {
  init()
}, {
  deep: true
})

const resetHandle = () => {
  form.value = {}
}

const searchHandle = () => {
  emit('search', form.value)
}

</script>

<style scoped lang="less">
  @import "index.less";
</style>
