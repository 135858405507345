<template>
  <template v-if="isRawPage"><RouterView /></template>
  <template v-else>
    <Header />
    <section class="page-body">
      <LeftSide />
      <section class="page-content">
        <Breadcrumb />
        <main><RouterView /></main>
      </section>
    </section>
  </template>
</template>

<script setup>
  import Header from '@/components/header'
  import LeftSide from '@/components/leftSide'
  import Breadcrumb from '@/components/breadcrumb'

  const pathName = window.location.pathname.replace('/', '')
  const isRawPage = [ 'login' ].includes(pathName)
</script>

<style lang="less">
  *{
    margin: 0;
    padding: 0;
  }
  html, body{
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    color: #333333;
  }
  #app {
    height: 100vh;
  }
  .page-body{
    display: flex;
    flex: 1;
  }
  .page-content{
    flex: 1;
    padding: 24px;
    height: calc(~"100vh - 56px");
    overflow: auto;
    box-sizing: border-box;
  }
</style>
