<template>
  <div class="pagination-wrap">
    <el-pagination
      size="small"
      background
      hide-on-single-page
      layout="prev, pager, next"
      :current-page="page"
      :page-size="size"
      :total="total"
      @current-change="val => emit('current-change', val)"
    />
  </div>
</template>

<script setup>
// eslint-disable-next-line no-undef
defineProps({
  page: {
    type: Number,
    default: 0
  },
  size: {
    type: Number,
    default: 50
  },
  total: {
    type: Number,
    default: 0
  },
})

// eslint-disable-next-line no-undef
const emit = defineEmits([ 'current-change' ])

</script>

<style scoped lang="less">
  @import "index.less";
</style>
