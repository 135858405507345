<template>
  <ListFilter :formList="filterFormList" @search="filterSearchHandle" />

  <el-button type="primary" style="margin-bottom: 16px;" @click="toggleDrawer(true)" :icon="Plus">新增</el-button>
  <el-table border :data="list" style="width: 100%">
    <el-table-column prop="portCode" label="港口代码" />
    <el-table-column prop="portCnName" label="中文名称" />
    <el-table-column prop="portEnName" label="英文名称" />
    <el-table-column prop="portCountry" label="所属国家" />
    <el-table-column prop="portLine" label="所属航线" />
    <el-table-column prop="portType" label="港口类型" />

    <el-table-column prop="edit" label="操作" fixed="right">
      <template #default="scope">
        <el-button link type="primary" size="small" @click="editHandle({...scope.row})">编辑</el-button>

        <el-popconfirm title="确定删除?">
          <template #reference>
            <el-button link type="danger" size="small">删除</el-button>
          </template>
          <template #actions="{ confirm, cancel }">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button type="danger" size="small" @click="delHandle(scope.row, confirm)">确定</el-button>
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <Pagination
    :page="page"
    :size="size"
    :total="total"
    @current-change="val => page = val"
  />

  <el-drawer v-model="drawer" direction="rtl" size="555px" :close-on-click-modal="false" :destroy-on-close="true" @close="handleDrawerClose">
    <template #header>
      <h4>编辑</h4>
    </template>
    <template #default>
      <div>
        <el-form :model="form" label-width="auto" style="max-width: 600px">
          <el-form-item label="港口代码" required>
            <el-input v-model="form.portCode" size="large" placeholder="请输入港口代码" />
          </el-form-item>
          <el-form-item label="中文名称">
            <el-input v-model="form.portCnName" size="large" placeholder="请输入中文名称" />
          </el-form-item>
          <el-form-item label="英文名称" required>
            <el-input v-model="form.portEnName" size="large" placeholder="请输入英文名称" />
          </el-form-item>
          <el-form-item label="所属国家" required>
            <el-input v-model="form.portCountry" size="large" placeholder="请输入所属国家" />
          </el-form-item>
          <el-form-item label="所属航线" required>
            <el-input v-model="form.portLine" size="large" placeholder="请输入所属航线" />
          </el-form-item>
          <el-form-item label="港口类型" required>
            <el-input v-model="form.portType" size="large" placeholder="请输入港口类型" />
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="toggleDrawer(false)">取消</el-button>
        <el-button type="primary" @click="submitHandle" :loading="loading" :disabled="(!form.portCode || !form.portEnName || !form.portCountry || !form.portLine || !form.portType)">保存</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script setup>
  import {onMounted, ref, watch} from 'vue'
  import * as Request from "@/assets/request";
  import {ElMessage} from "element-plus";
  import ListFilter from '@/components/listFliter'
  import Pagination from '@/components/spagination'
  import { Plus } from '@element-plus/icons-vue'

  const list = ref([])
  const listQuery = ref({})
  const total = ref(0)
  const size = ref(50)
  const page = ref(1)
  const getList = async () => {
    const result = await Request.post({
      url: '/port/managerQuery',
      data: {
        ...listQuery.value,
        page: page.value,
        size: size.value
      }
    })
    if(result?.status === 200) {
      list.value = result.data.list;
      total.value = result.data.total;
    }
  }
  onMounted(() => {
    getList()
  })
  watch(() => [ listQuery.value, page.value ], () => {
    getList();
  }, { deep: true })

  const drawer = ref(false)
  const handleDrawerClose = () => {
    form.value = {}
  }
  const form = ref({
    // id: null,
    portCode: '',
    portCnName: '',
    portEnName: '',
    portCountry: '',
    portLine: '',
    portType: ''
  })
  const toggleDrawer = (flag) => {
    drawer.value = flag
  }
  const editHandle = (item) => {
    toggleDrawer(true)
    form.value = item
  }
  const loading = ref(false)
  const submitHandle = async () => {
    let url = '/port/add';
    if(form.value?.id){
      url = '/port/update';
    }
    loading.value = true
    const result = await Request.post({
      url,
      data: form.value
    })
    loading.value = false
    if(result?.status === 200) {
      ElMessage.success('保存成功');
      toggleDrawer(false)
      getList()
      form.value = {
        // id: null,
        portCode: '',
        portCnName: '',
        portEnName: '',
        portCountry: '',
        portLine: '',
        portType: ''
      }
    }
  }
  const delHandle = async (data, callback) => {
    const result = await Request.del({
      url: `/port/delete/${data.id}`
    })
    if(result?.status === 200) {
      ElMessage.success('操作成功')

      callback()
      getList()
    }
  }

  /**
   * filter
   * @type {Ref<UnwrapRef<*[]>, UnwrapRef<*[]> | *[]>}
   */
  const filterFormList = ref([])
  const filterFormListInit = () => {
    filterFormList.value = [
      {
        type: 'input', field: 'portCode',
        label: '港口代码', placeholder: '请输入',
      },
      {
        type: 'input', field: 'portEnName',
        label: '英文名', placeholder: '请输入',
      },
      {
        type: 'input', field: 'portCnName',
        label: '中文名', placeholder: '请输入',
      },
    ]
  }
  onMounted(() => {
    filterFormListInit()
  })
  const filterSearchHandle = (formValue) => {
    listQuery.value = { ...formValue }
    page.value = 1
  }
</script>

<style scoped lang="less">
@import "index.less";
</style>
