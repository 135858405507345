import moment from "moment";
import ImageViewer from "@luohc92/vue3-image-viewer";
import '@luohc92/vue3-image-viewer/dist/style.css';

export const getSearchByKey = (key, search = window.location.search) => {
	if(!search) return '';

	const _search = search.slice(1);
	const _searchArr = _search.split('&');
	const _map = {};
	_searchArr.map(item => {
		const _item = item.split('=');
		if(_item[0] && _item[1]) {
			_map[_item[0]] = _item[1];
		}
	});
	return _map[key] ? decodeURIComponent(_map[key]) : '';
};

export const addZeroForNum = (num) => {
	num = Number(num);
	num = num < 10 ? `0${num}` : ''+num;
	return num;
};

export const getExtname = (url) => {
	let extName = '';
	if (url) {
		const urlArr = url.split('.')
		if (urlArr?.length > 0) {
			extName = urlArr[urlArr.length - 1]
		}
	}
	return extName;
};

export const getFileType = (filePath) => {
	const extName = getExtname(filePath);
	if([ 'mp4', 'mov' ].includes(extName)){
		return 'video'
	}
	if([ 'jpg', 'jpeg', 'png', 'gif' ].includes(extName)){
		return 'img'
	}
	return ''
}

/**
 * 倒计时
 * @param second，默认60秒
 * @param callback
 * @returns {{start: start}}
 */
export const countdown = (second = 60, callback) => {
	let timer = null;
	return {
		start: () => {
			if(second < 0) {
				clearTimeout(timer);
				timer = null;
				callback && typeof callback == 'function' && callback();
				return;
			}
			timer = setTimeout(function(){
					callback && typeof callback == 'function' && callback(second);
					second -= 1;

					countdown(second, callback).start();
				}
				,1000);
		}
	}
};

export const setStaticUrl = (url, ori = false) => {
	if(!url || url.startsWith('http') || url.startsWith('//')) return url;

	if(ori) {
		const etxNames = url.split('.');
		if(etxNames?.length > 0) {
			const etxName = etxNames[etxNames.length - 1];
			if(etxName) {
				url = url.replace(`_s.${etxName}`, `.${etxName}`);
			}
		}
	}

	return import.meta.env.VITE_STATIC_PREFIX + url;
};

export const user = {
	setInfo: (userInfo = {}) => {
		window.sessionStorage.setItem('uinfo', JSON.stringify(userInfo));
	},
	getInfo: () => {
		const res = window.sessionStorage.getItem('uinfo') || '{}';
		if (res && res !== '{}') {
			return JSON.parse(res);
		}
		return null;
	},
	setToken: token => window.sessionStorage.setItem('utoken', token),
	getToken: () => window.sessionStorage.getItem('utoken'),
	logout: () => {
		window.sessionStorage.removeItem('userinfo');
		window.sessionStorage.removeItem('utoken');
		window.mapDataCaches = {
			map: null,
			polygon: {},
			pointCluster: null,
			list: {},
			listFlag: {}
		};
	}
}

export const store = {
	setKey: key => window.sessionStorage.setItem('storeKey', key),
	getKey: () => window.sessionStorage.getItem('storeKey'),
	setToken: token => window.sessionStorage.setItem('stoken', token),
	getToken: () => window.sessionStorage.getItem('stoken'),
	logout: () => {
		console.log('11111')
		window.sessionStorage.removeItem('stoken')
	},
}

export const uniqueArray = (arr, uniqueKey) => {
	const map = {};
	const newArr = [];
	for(let i = 0, len = arr.length; i < len; i++) {
		const item = arr[i];
		if(map[uniqueKey] !== item[uniqueKey]) {
			map[uniqueKey] = item[uniqueKey];
			newArr.push(item);
		}
	}
	return newArr;
};

export const displayTimeFormat = (datetime, format = 'YYYY.MM.DD') => {
	if(!datetime) return '';
	return moment(datetime).format(format);
}

export const toast = (text) => {
	const toast = document.getElementById('toast');
	toast.innerText = text;
	toast.style.display = 'block';
	setTimeout(() => {
		toast.style.display = 'none';
	}, 1500);
}

export const imageViewer = (list = [], index = 0, config = {}) => {
	if(!list || list.length === 0 || list[index]?.type === 'video') return;

	let videoLength = 0;
	const previewList = [];
	list.map(lst => {
		if(lst?.url && lst?.type !== 'video') {
			lst.url = setStaticUrl(lst.url, true);
			previewList.push(lst.url);
		}
		if(lst?.type === 'video') {
			videoLength += 1;
		}
	});
	index -= videoLength;

	ImageViewer({
		zIndex: 2000,
		showDownload: true,
		showThumbnail: true,
		handlePosition: "bottom",
		maskBgColor: "rgba(0,0,0,0.7)",
		onClose: () => {
			console.log("close");
		},
		...config,
		images: previewList,
		curIndex: index,
	});
}

export const format_with_substring = (number) => {
  // 数字转为字符串，并按照 .分割
  let arr = (number + '').split('.');
  let int = arr[0] + '';
  let fraction = arr[1] || '';
  // 多余的位数
  let f = int.length % 3;
  // 获取多余的位数，f可能是0， 即r可能是空字符串
  let r = int.substring(0, f);
  // 每三位添加','金额对应的字符
  for (let i = 0; i < Math.floor(int.length / 3); i++) {
    r += ',' + int.substring(f + i * 3, f + (i + 1) * 3);
  }
  // 多余的位数，上面
  if (f === 0) {
    r = r.substring(1);
  }
  // 调整部分和小数部分拼接
  return r + (fraction ? '.' + fraction : '');
}