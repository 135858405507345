<template>
  <secction class="login-container">
    <h2>欢迎登录 Sigma 业务管理系统</h2>
    <div class="login-wrap">
      <el-form :model="form" label-width="auto" label-position="top" style="width: 100%;">
        <el-form-item label="手机号" required>
          <el-input v-model="form.phone" size="large" placeholder="请输入手机号" />
        </el-form-item>

        <el-form-item label="密码" required>
          <el-input v-model="form.password" type="password" size="large" placeholder="请输入密码" />
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="large" @click="onSubmit" :loading="loading" style="width: 100%;">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </secction>
</template>

<script setup>
  import { ref } from "vue";
  import * as Request from "@/assets/request";
  import * as Utils from "@/assets/utils";
  import {ElMessage} from "element-plus";

  /**
   * 表单
   * @type {Reactive<{file: string, name: string, desc: string}>}
   */
  const form = ref({
    phone: '',
    password: '',
  })
  // 提交表单
  const loading = ref(false)
  const onSubmit = async () => {
    if(!form.value.phone) return ElMessage.error('请输入手机号');
    if(!form.value.password) return ElMessage.error('请输入密码');

    loading.value = true
    const result = await Request.post({
      url: '/login/pwd',
      data: {
        ...form.value,
        type: 2
      }
    })
    loading.value = false
    if(result?.status === 200) {
      ElMessage.success('登录成功');
      Utils.user.setToken(result.data.token)

      const userInfo = result.data;
      if(userInfo.token) delete userInfo.token
      Utils.user.setInfo(userInfo)

      setTimeout(() => {
        window.location.href = '/home'
      }, 200)
    }
  }
</script>

<style scoped>
@import "index.less";
</style>
